// .sdk_main-flow-container {
//   display: flex;
//   justify-content: space-between;
//   @media only screen and (max-width: 600px) {
//     flex-direction: column;
//   }
//   .left-flow {
//     width: 46%;
//     padding: 18px 0px;
//     /* background: #0f0a2c; */
//     @media only screen and (max-width: 600px) {
//       width: 100%;
//       padding: 16px 0;
//     }
//     @media only screen and (min-width: 600px) and (max-width: 900px) {
//       padding: 16px 0;
//     }
.btn-desktop-view {
  @media only screen and (max-width: 600px) {
    display: none;
  }
  .btn-client-sdk {
    background: #6412e9;
    border-radius: 5px;
    border-color: #6412e9;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    width: 48%;
    height: 40px;

    &:hover {
      color: #4096ff;
      border-color: #4096ff;
    }
    @media only screen and (min-width: 601px) and (max-width: 1000px) {
      width: 48%;
    }
    // @media only screen and (min-width: 1200) and (max-width: 1300px) {
    //   width: 48%;
    // }
    // @media only screen and (min-width: 1300) and (max-width: 1650px) {
    //   width: 48%;
    // }
  }
}
//   }
//   .right-flow {
//     flex: 1;
//     // height: 398px;
//     margin-top: -3rem;
//     width: 54%;
//     @media only screen and (max-width: 600px) {
//       margin-top: -22px;
//       margin-bottom: 10px;
//       width: 100%;
//     }
//     .inner-div {
//       border: 0.931707px dashed #f90c0c;
//       height: 100%;
//       overflow: auto;
//     }
//   }
.btn-mobile-view {
  display: none;
  @media only screen and (min-width: 501px) and (max-width: 600px) {
    display: block;
    .btn-client-sdk {
      background: #6412e9;
      border-radius: 5px;
      border-color: #6412e9;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      width: 47%;
      height: 40px;
      @media only screen and (max-width: 600px) {
        width: 47%;
      }

      @media only screen and (min-width: 600px) and (max-width: 900px) {
        width: 48%;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    display: block;
    .btn-client-sdk {
      background: #6412e9;
      border-radius: 5px;
      border-color: #6412e9;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      // width: 100%;
      height: 40px;
      @media only screen and (max-width: 600px) {
        width: 48%;
      }
    }
  }
}
// }

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0f0a2c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0f0a2cab;
}

// Admin Experience

.btn-backend-sdk {
  background: #6412e9;
  border-radius: 5px;
  border-color: #6412e9;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  width: 100%;
  height: 40px;
}
