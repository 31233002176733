.main-row {
  height: 100%;
  .left-container {
    overflow-y: auto;
    height: 100%;
    .sign-in-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 82px;
      // overflow-y: auto;
    }
  }
  .right-container {
    height: 100%;
  
    @media only screen and (max-width: 600px) {
      display: none;
    }
    @media only screen and (min-width: 600px) and (max-width: 1000px) {
      display: none;
    }
  
    .img-banner {
      border-bottom-left-radius: 200px;
      height: 100%;
    }
  }
}

