.header-section {
  width: 75%;
  position: fixed;
  border-color: transparent;
  background: rgba(244, 247, 254, 0.2);
  backdrop-filter: blur(20px);
  background-size: cover;
  border-radius: 16px;
  border-width: 1.5px;
  border-style: solid;
  min-height: 75px;
  line-height: 25.6px;
  -webkit-margin-start: auto;
  transition-delay: 0s, 0s, 0s, 0s;
  margin-top: 18px;
  padding: 8px;
  box-shadow: none;
  z-index: 9999;

  @media screen and (max-width: 600px) {
    width: 94%;
    margin-top: 13px;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    width: 92%;
    margin-top: 13px;
  }

  .header-row {
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .ant-col-8 {
      max-width: 100%;
      padding: 0 6px;

      @media screen and (max-width: 600px) {
        padding: 0 11px;
      }
      @media screen and (min-width: 600px) and (max-width: 1200px) {
        padding: 0;
      }
    }
  }

  .search-section {
    // background: white;
    padding: 10px;
    border-radius: 30px;
    box-shadow: 14px 17px 40px 4px rgb(112 144 176 / 18%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    .search-icon {
      position: absolute;
      top: 18px;
      z-index: 2;
      left: 26px;
      font-size: 16px;
      @media screen and (max-width: 600px) {
        left: 28px;
      }
    }

    .ham-icon {
      display: none;
      @media screen and (max-width: 600px) {
        display: block;
        background: #d0d8e3;
      }
      @media screen and (min-width: 600px) and (max-width: 1200px) {
        display: block;
        background: #d0d8e3;
      }
    }
    .info-icon,
    .bell-icon,
    .moon-icon {
      font-size: 16px;
      color: #d0d8e3;
    }

    .bell-icon {
      .ant-popover .ant-popover-inner-content {
        padding: 13px;
      }
      .popover {
        .ant-popover .ant-popover-inner {
          border-radius: 23px !important;
          padding: 20px;
        }
      }
    }
    .search-input {
      // background: #f4f7fe;
      border-radius: 30px;
      border: none;
      font-weight: 500;
      height: 40px;
      padding-top: 11px;
      font-weight: 600;
      color: #b1bdcc;
      padding-bottom: 11px;
      outline: 2px solid transparent;
      padding: 0 33px;

      @media screen and (max-width: 600px) {
        padding: 0 26px;
        width: 100%;
      }
    }
  }
  .menu-text {
    font-size: 33px;
    font-weight: 600;
    line-height: 1.271429;
    text-align: start;

    @media screen and (min-width: 600px) and (max-width: 1200px) {
      font-size: 25px;
    }
  }
  .avtar {
  }
}
// .ant-popover {
//   left: 1070px !important;
//   top: 100px !important;
//   width: 18%;
// }
// ant drawer style start
.ant-drawer .ant-drawer-header {
  @media screen and (max-width: 600px) {
    border-bottom: none;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    border-bottom: none;
  }
}
.ant-drawer .ant-drawer-header-title {
  @media screen and (max-width: 600px) {
    justify-content: end;
  }
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    justify-content: end;
  }
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}
// ant drawer style end

.ham-btn {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}

//ant popover styles
.ant-popover .ant-popover-inner {
  border-radius: 23px !important;
  padding: 20px;
}
.ant-popover .ant-popover-content {
  left: -43px;
  top: 19px;

  @media screen and (max-width: 600px) {
    left: -8px;
    top: 16px;
    padding: 24px;
  }
  .ant-popover-placement-bottom .ant-popover-arrow {
    transform: translateY(0);
  }
  .ant-popover-placement-bottom .ant-popover-arrow {
    left: 0;
    transform: translateX(0%) translateY(0);
  }
}
//ant popover styles
