.dropdown {
  gap: 8px;
  background: #f4f7fe;
  width: 30px;
  height: 30px;
  border-radius: 9px;
  padding: 4px;
  padding-top: -15px;
  font-size: 32px;
  padding-bottom: 23px;
  color: #422afb;
}
